import { MyLink } from 'components/Layout/Link';
import blocksToText from 'new-studio/utils/blocksToText';
import React from 'react';
import { BlockContent } from 'src/types/sanity/schema';
import styled from 'styled-components';
import { Product } from '../types';
import { PortableText } from 'utils/sanity/sanity';
const ProductDescriptionStyles = styled.div`
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: flex-start;

    .long {
        order: 1;
    }

    a {
        order: 2;
    }
`;
interface ProductDescriptionProps {
    // metafields: ProductsData_products_edges_node_metafields;
    isSingle: boolean | undefined;
    product: Product;
    isDescriptionVis?: boolean;
}

const ProductDescription: React.FC<ProductDescriptionProps> = ({ isSingle, product, isDescriptionVis }) => {
    return (
        <ProductDescriptionStyles className={`description ProductDescription-container`}>
            {isDescriptionVis && product.description ? (
                <PortableText
                    blocks={product.description}
                    className={`description ${isDescriptionVis ? 'show' : 'hide'}`}
                />
            ) : product.store.descriptionHtml ? (
                <div
                    className={`description ${isDescriptionVis ? 'show' : 'hide'}`}
                    dangerouslySetInnerHTML={{ __html: product.store.descriptionHtml }}
                />
            ) : null}
        </ProductDescriptionStyles>
    );
};

export default ProductDescription;
