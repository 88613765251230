import { ProductsData_products_edges_node } from '../../../src/types/shopify/storefront/ProductsData';
import { ProductCustomization, CartItemProductNode } from '../interfaces';
import { Product } from '../types';

export function addToCart(
    product: Product,
    customization: ProductCustomization,
    addFn: (product: CartItemProductNode) => void | undefined,
): void {
    if (addFn && product) {
        const cartItem: CartItemProductNode = { ...product, customization };
        cartItem.customization = customization;

        addFn(cartItem);
    }
}
