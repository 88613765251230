import { ProductCustomization, customizationTemplate } from '../interfaces';
import { Product } from '../types';

export function generateBaseCustomization(product: Product, quantity: number): ProductCustomization {
    const template: ProductCustomization = {
        ...customizationTemplate,
        currentVariant: product.store.variants[0],
    };
    const { title, gid: productId, variants } = product.store;
    const match = variants[0];

    const totalPrice = quantity * match?.store.price;
    template.title = title;
    template.productId = productId;
    template.totalPrice = totalPrice.toFixed(2);
    template.quantity = quantity;
    template.currentVariant = product.store.variants?.[0];

    return template;
}
