import { ProductCustomization } from '../interfaces';
import { Product } from '../types';
import { generateBaseCustomization } from './genBlankCustomization';

export function getCustomization(product: Product, map: ProductCustomization[]): ProductCustomization {
    const item = map.find((el: ProductCustomization, idx: number) => el.productId === product.store.gid);

    if (!item) {
        return generateBaseCustomization(product, 1);
    }
    return item;
}
