import { NextComponentType, NextPage, NextPageContext } from 'next';
import { dfContactForm, dfUserNote, dfVariantOption } from '../../core/defaults';
export type User = {
    firstName: string;
    lastName: string;
    address1?: string;
    address2?: string;
    city?: string;
    province?: string;
    country?: string;
    zip?: string;
    phone?: string;
    shippingDate?: Date | string;
};

export type UserNote = typeof dfUserNote;
export type ContactForm = typeof dfContactForm;

export enum PageType {
    website = 'website',
    article = 'article',
}

export enum RobotsContent {
    follow = 'follow',
    index = 'index',
    no_follow = 'nofollow',
    no_index = 'noindex',
}

export type MetaTags = {
    title: string;
    author?: string;
    description: string;
    type: PageType;
    og_type?: PageType;
    image: string;
    robots: string;
    og_title?: string;
    og_description?: string;
    og_URL?: string;
    canonical: string;
    og_image?: string;
    og_site_name?: string;
    twitter_card?: string;
    twitter_description?: string;
    twitter_site?: string;
    twitter_domain?: string;
    twitter_img?: string;
};

export interface GenericObject {
    [key: string]: string | number;
}

export type _MenuLocations = _ContextMenuType | 'footer-1' | 'footer-2' | 'footer-3' | 'footer-4';

export type _ContextMenuType = 'main' | 'topline-left' | 'topline-right' | 'shop' | 'journal' | 'about';

export type NavLocations = 'main' | 'topline-left' | 'topline-right';

export type DFVariantOption = typeof dfVariantOption;

export type LayoutPage<T> = NextPage<T> & {
    getLayout?: (page: NextComponentType<NextPageContext, any, unknown>) => void;
};
