import React from 'react';
import styled from 'styled-components';
import mixins from '../mixins';
import { bbTheme } from '../theme';

const ReviewStarStyles = styled.svg``;

const ReviewStarsLayout = styled.div`
    ${mixins.flex};
    justify-content: flex-start;
`;

interface Props {
    fill?: string;
}

const ReviewStar: React.FC<Props> = ({ fill }) => {
    return (
        <ReviewStarStyles
            className="ReviewStar-container"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9 0L11.0206 6.21885H17.5595L12.2694 10.0623L14.2901 16.2812L9 12.4377L3.70993 16.2812L5.73056 10.0623L0.440492 6.21885H6.97937L9 0Z"
                fill={fill || '#EEE8E8'}
            />
            <path
                d="M9 0L11.0206 6.21885H17.5595L12.2694 10.0623L14.2901 16.2812L9 12.4377L3.70993 16.2812L5.73056 10.0623L0.440492 6.21885H6.97937L9 0Z"
                fill={fill || '#EEE8E8'}
            />
        </ReviewStarStyles>
    );
};

export { ReviewStar };

interface ReviewStarsProps {
    score: number;
}

const ReviewStars: React.FC<ReviewStarsProps> = ({ score }) => {
    const stars = [1, 2, 3, 4, 5];

    return (
        <ReviewStarsLayout className="ReviewStars-container">
            {stars.map((star, idx) => (
                <ReviewStar key={idx} fill={score >= star ? bbTheme.colors.backgrounds_muted_honey : undefined} />
            ))}
        </ReviewStarsLayout>
    );
};
export default ReviewStars;
