import { KFLoading, KFSuccess } from 'components/animations/keyframes';
import { device } from 'components/styles/mixins';
import styled, { keyframes } from 'styled-components';

// const loadingMob = keyframes`
//   from {
//     opacity:0;
//     top: -15%
//     /* rotate: 0; */
//   }

//   to {
//     top: 10%;
//     opacity: 1;
//     /* rotate: 360deg; */

//   }
// `;

export interface ModalStyleProps {
    bgColor?: string | undefined;
    coords?: { t: string; r: string; b: string; l: string };
    mousePos?: { x: number; y: number };
}
export const ModalStyles = styled.div.attrs((props: ModalStyleProps) => ({
    style: { transformOrigin: props.mousePos ? props.mousePos.x + 'px ' + props.mousePos.y + 'px ' : 'center' },
}))<ModalStyleProps>`
    position: relative;
    top: ${({ coords }) => (coords ? coords.t : '5%')};
    right: ${({ coords }) => (coords ? coords.r : 'auto')};
    left: ${({ coords }) => (coords ? coords.b : 'auto')};
    bottom: ${({ coords }) => (coords ? coords.l : 'auto')};
    @media ${device().small} {
        top: ${({ coords }) => (coords ? coords.t : '20%')};
        right: ${({ coords }) => (coords ? coords.r : 'auto')};
        left: ${({ coords }) => (coords ? coords.b : 'auto')};
        bottom: ${({ coords }) => (coords ? coords.l : 'auto')};
    }
    /* height: 90vh; */
    width: max-content;
    /* min-height: 70%; */
    max-width: 96vw;
    @media ${device().xs} {
        max-width: 80rem;
    }
    margin: 0 auto;
    padding: 1em;
    z-index: 1001;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    /* transition: all 300ms ease-in-out; */
    animation: ${KFLoading} 400ms ease-in;
    /* transform-origin: ${({ theme, mousePos }) => (mousePos ? mousePos.x + 'px ' + mousePos.y + 'px ' : 'center')}; */
    opacity: 1;
    background-color: ${({ bgColor }) => bgColor || ''};
    /* overflow: scroll; */
    box-shadow: ${({ theme, bgColor }) => (bgColor === 'transparent' ? 'none' : theme.bShadow.dbl)};
    border-radius: ${({ theme }) => theme.borderRadius.md};

    /* @media ${device().small} {
        width: 80vw;
    } */
    &.success {
        animation: ${KFSuccess} 600ms ${({ theme }) => theme.animations.beziers.bounceOut};
        transform-origin: center;
    }

    .close-btn {
        position: absolute;
        top: 0;
        right: 0;
    }
`;

export const CartModalStyles = styled(ModalStyles)`
    background-color: ${({ theme, bgColor }) => bgColor || theme.colors.white};
    box-shadow: ${({ theme, bgColor }) => (bgColor === 'transparent' ? 'none' : theme.bShadow.dbl)};
`;

export const ModalBackDropStyles = styled.div`
    backface-visibility: hidden;
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    background-color: ${({ theme }) => theme.colors._overlay};
    z-index: 1001;
`;
