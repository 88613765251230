import { SelectedOptionInput } from 'src/types/sf-globalTypes';
import { productVariantbyOptions_productByHandle_variantBySelectedOptions } from 'src/types/shopify/storefront/productVariantbyOptions';
import { checkout } from '../../src/types/shopify/storefront/checkout';
import {
    ProductsData_products_edges_node,
    ProductsData_products_edges_node_variants_edges_node,
} from '../../src/types/shopify/storefront/ProductsData';
import {
    singleProductByHandle_productByHandle,
    singleProductByHandle_productByHandle_variants_edges_node,
} from '../../src/types/shopify/storefront/singleProductByHandle';
import { ShippingRule } from './helpers/handleShippingRules';
import { CartShippingRule, CheckoutStep, Product, ProductVariant } from './types';

export interface CartItemProductNode extends Product {
    customization: ProductCustomization;
}
export interface CustomizationUpdates {
    key:
        | 'quantity'
        | 'variantPrice'
        | 'variantTitle'
        | 'variantId'
        | 'value'
        | 'variantShippingRule'
        | 'selectedOptions';
    value: string | number | SelectedOptionInput[];
}

export type SFVariant =
    | productVariantbyOptions_productByHandle_variantBySelectedOptions
    | singleProductByHandle_productByHandle_variants_edges_node
    | ProductsData_products_edges_node_variants_edges_node;

export interface ProductCustomization {
    title: string;
    productId: string;
    currentVariant: ProductVariant;
    totalPrice: string;
    quantity: number;
    productShippingRules: ShippingRule[] | null;
    variantShippingRule: string;
}
export type BaseCustomization = Omit<ProductCustomization, 'currentVariant'>;

// interface OriginalLite extends Omit<Original, 'link'> {}
export const customizationTemplate: BaseCustomization = {
    title: '',
    productId: '',
    totalPrice: '',
    quantity: 1,
    productShippingRules: null,
    variantShippingRule: '',
};

export interface GiftCardStatus {
    code: string;
    last4: string;
    id: string;
    applied: boolean;
    removed: boolean;
    remaining: string;
}

export interface InitialCheckoutState {
    // Checkout State
    isLoading: boolean;
    isFixedCalendar: boolean;
    isCheckoutStarted: boolean;
    isCalendarVis: boolean;
    isCheckoutReady: boolean;
    isCheckoutReloading: boolean;
    isCheckoutReloaded: boolean;
    checkoutStep: number;
    userSteps: CheckoutStep[];
    shippingRule: CartShippingRule;
    resets: any[];
    checkoutData: checkout | undefined;

    // Checkout Data
    // checkoutId: string;
    // webUrl: string;
    // note: { to: string; from: string; note: string };
    // shippingAddr: checkoutShippingAddressUpdateV2_checkoutShippingAddressUpdateV2_checkout_shippingAddress | undefined;
    // email: string;
    // shipDate: string | Date;
    // shipRateHandle: string;
    // numItems: number;
    // lineItems: replaceItems_checkoutLineItemsReplace_checkout_lineItems;
}
