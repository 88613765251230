import { Product } from 'components/shop/types';
import { Article, Route } from 'src/types/sanity/schema';
import { Page } from 'src/types/sanity/new-schema';
import { urlFor } from 'utils/sanity/sanity';
import { ProductMetaTags } from '../src/interfaces/tag';
import { MetaTags, PageType, RobotsContent } from '../src/types/globalTypes';
import { defaultMetaTags, siteLogo, siteTitle } from './constants';
import blocksToText from 'new-studio/utils/blocksToText';

/**
 * 
    Meta Data
 */

export function genProductMetaData(product: Product): ProductMetaTags {
    if (!product) return genDefaultMetaTags();
    const pi = [product.store.previewImageUrl];
    const productImage = pi[0];

    const data = {
        canonical: `${defaultMetaTags.canonical}/shop/products/${product.store.slug.current}`,
        description: blocksToText(product?.description),
        image: productImage,
        robots: `${RobotsContent.follow},${RobotsContent.index}`,
        title: product.store.title,
        type: PageType.website,
        author: siteTitle,
        og_type: PageType.website,
        og_title: product.store.title,
        og_description: blocksToText(product?.description) || '',
        og_URL: `${defaultMetaTags.canonical}/shop/products/${product.store.slug.current}`,
        og_image: productImage,
        og_site_name: defaultMetaTags.title,
        twitter_card: '',
        twitter_description: blocksToText(product?.description) || '',
        twitter_site: defaultMetaTags.title,
        twitter_domain: `${defaultMetaTags.canonical}/shop/products/${product.store.slug.current}`,
        twitter_img: productImage,
    };

    return data;
}

export function genJournalMetaData(article: Article): MetaTags {
    if (!article) return genDefaultMetaTags();
    const d = article.excerpt?.split('.')[0] || article.excerpt || '';
    const description = d.replace(/<[^>]*>/g, '');
    const data = {
        canonical: `${defaultMetaTags.canonical}/journal/${article.slug || ''}`,
        description: description,
        image: urlFor(article.mainImage).url() || '',
        robots: `${RobotsContent.follow},${RobotsContent.index}`,
        title: article.title || '',
        type: PageType.article,
        author: article.author['name'] || '',
        og_type: PageType.website,
        og_title: article.title || '',
        og_description: description,
        og_URL: `${defaultMetaTags.canonical}/journal/${article.slug || ''}`,
        og_image: urlFor(article.mainImage).url() || '',
        og_site_name: defaultMetaTags.title,
        twitter_card: '',
        twitter_description: description,
        twitter_site: defaultMetaTags.title,
        twitter_domain: `${defaultMetaTags.canonical}/journal/${article.slug || ''}`,
        twitter_img: urlFor(article.mainImage).url() || '',
    };

    return data;
}

export function genPageMetaData(page?: Page): MetaTags {
    if (!page) return genDefaultMetaTags();
    const hero = page.pageBuilder?.find((el) => el._type === 'hero');
    const heroImageUrl = hero ? urlFor(hero['image']).url() : null;
    const pageImage = urlFor(page?.seo?.image).url();
    const logoImage = urlFor(siteLogo).url();
    const description = page?.seo?.description || hero?.['tagline'] || defaultMetaTags.description;
    const slugObj = page?.slug || page.slug;
    const slug = typeof slugObj === 'object' ? slugObj.current : slugObj;
    return {
        canonical: `${defaultMetaTags.canonical}/${slug || ''}`,
        description,
        image: heroImageUrl || pageImage || logoImage || '',
        robots: `${RobotsContent.follow},${RobotsContent.index}`,
        title: page?.title || page.title,
        type: PageType.website,
        author: 'Bumblebee Flower Farm',
        og_type: PageType.website,
        og_title: page?.title || page.title || '',
        og_description: description,
        og_URL: `${defaultMetaTags.canonical}/`,
        og_image: heroImageUrl || pageImage || logoImage || '',
        og_site_name: defaultMetaTags.title,
        twitter_card: '',
        twitter_description: description,
        twitter_site: defaultMetaTags.title,
        twitter_domain: `${defaultMetaTags.canonical}/`,
        twitter_img: heroImageUrl || pageImage || logoImage || '',
    };
}

export function genDefaultMetaTags() {
    return {
        canonical: `${defaultMetaTags.canonical}/`,
        description: defaultMetaTags.description,
        image: defaultMetaTags.image,
        robots: `${RobotsContent.follow},${RobotsContent.index}`,
        title: defaultMetaTags.title,
        type: PageType.website,
        author: 'Bumblebee Flower Farm',
        og_type: PageType.website,
        og_title: defaultMetaTags.title,
        og_description: defaultMetaTags.description,
        og_URL: `${defaultMetaTags.canonical}/`,
        og_image: defaultMetaTags.image,
        og_site_name: defaultMetaTags.title,
        twitter_card: '',
        twitter_description: defaultMetaTags.description,
        twitter_site: defaultMetaTags.title,
        twitter_domain: `${defaultMetaTags.canonical}/`,
        twitter_img: defaultMetaTags.image,
    };
}
