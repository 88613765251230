import { Row } from 'components/Layout/grid/Row';
import { device } from 'components/styles/mixins';
import styled from 'styled-components';
import { SHOPIFY_BASE_IMAGE_SIZE_W } from '../constants';

export const ProductIndexStyles = styled(Row)`
    overflow-y: hidden;
    justify-content: center;
    .column-wrapper {
        max-width: ${({ theme }) => theme.breakpoints.xl}px;
        @media ${device().xl} {
            margin: 0 auto;
        }
    }
    .collection-product {
        --gap: ${({ theme }) => theme.spacing.m};
        display: block;

        @media ${device().medium_large} {
            display: flex;
        }
    }

    .ProductDescription-container {
        max-width: ${SHOPIFY_BASE_IMAGE_SIZE_W}px;
        @media ${device(true).medium_large} {
            margin: 0 auto;
        }
    }
    .ProductMeta-container {
        margin: 0 auto;
        max-width: ${SHOPIFY_BASE_IMAGE_SIZE_W}px;
    }
    @media only screen and (max-width: 768px) {
        align-items: flex-end;
    }
    @media only screen and (max-width: 768px) {
        flex-flow: column nowrap;
        align-items: center;
    }
`;

export const ProductDetailStyles = styled.div`
    display: grid;
    grid-template-columns: ${({ theme }) => theme.spacing.gutter} 1fr ${({ theme }) => theme.spacing.gutter};
    grid-auto-rows: min-content;
    gap: ${({ theme }) => theme.spacing.s} 0;

    &.quick-view {
        & + .buttons {
            justify-content: center;
        }
    }

    .product-image {
        grid-column: 2 / -2;
        height: 50rem;
        position: relative;
        @media ${device().medium_large} {
            height: 100%;
        }
    }

    & > *:not(.full-bleed) {
        grid-column: 2 / -2;
    }

    .full-bleed {
        grid-column: 1 / -1;
    }

    @media ${device().medium_large} {
        grid-template-columns: ${({ theme }) => theme.spacing.gutter} repeat(12, 1fr) ${({ theme }) =>
                theme.spacing.gutter};
        gap: ${({ theme }) => theme.spacing.s};
        .product-image {
            grid-column: 1 / 9;
            grid-row: 1 / 3;
        }
        .ProductMeta-container {
            padding-top: ${({ theme }) => theme.spacing.xxl};
            grid-column: 9 / -2;

            & .title {
                grid-column: 3 / -2;
            }
        }
        .ProductControls-container {
            grid-column: 9 / -2;
        }

        .ProductDetails-container {
            grid-template-columns: repeat(12, 1fr);
            align-items: start;

            .Accordian-container,
            .accordian-title {
                max-width: 100%;
                grid-column: 1 / 8;
            }
            .TableColumn-container,
            .table-title {
                grid-column: 8 / -1;
            }

            .table-title {
                grid-row: 1 / 1;
            }
        }
    }

    .back-btn {
        position: absolute;
        top: 0;
        right: 0;
        min-width: max-content;
    }
`;
