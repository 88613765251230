import Button from 'components/Layout/Buttons';
import { Column } from 'components/Layout/grid/Column';
import { Row } from 'components/Layout/grid/Row';
import { ElementLink, MyLink } from 'components/Layout/Link';
import ReviewStars from 'components/styles/general-elements/ReviewStar';
import { device } from 'components/styles/mixins';
import { bbTheme } from 'components/styles/theme';
import Title from 'components/styles/Title';
import useWindowSize from 'hooks/useWindowSize';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { useContext } from 'react';
import { SelectedOptionInput } from 'src/types/sf-globalTypes';
import { CartContext } from 'state/context/global';
import styled from 'styled-components';
import { logSingleProductClick } from 'utils/analytics';
import { PortableText } from 'utils/sanity/sanity';
import { slugify } from 'utils/stringUtils';
import { modifyAlpha } from 'utils/styleFunctions';
import { SHOPIFY_BASE_IMAGE_SIZE_H, SHOPIFY_BASE_IMAGE_SIZE_W } from '../constants';
import { addToCart } from '../helpers/addToCart';
import { createSelectedOptionInput } from '../helpers/createOptions';
import { generateBaseCustomization } from '../helpers/genBlankCustomization';
import { getCustomization } from '../helpers/getCustomization';
import { isInCart } from '../helpers/handleCartButton';
import { optionsToQueryString } from '../helpers/queryStringFunctions';
import { shopifyImageLoader } from '../helpers/shopifyImageLoader';
import { ProductCustomization } from '../interfaces';
import { Product, ProductVariant } from '../types';
import ProductDescription from './ProductDescription';

const ReactTooltip = dynamic(() => import('react-tooltip'), {
    ssr: false,
});

const ProductMetaStyles = styled(Row)`
    margin: 0;
    transition: all 200ms ease-in-out;

    .PopupProduct-container & {
        padding: 0 ${({ theme }) => theme.spacing.s};
    }
    &:hover {
        cursor: pointer;
    }

    .review-column {
        text-align: right;
    }
    .image {
        position: relative;
        overflow: hidden;
        width: ${SHOPIFY_BASE_IMAGE_SIZE_W}px;
        height: ${SHOPIFY_BASE_IMAGE_SIZE_H}px;
        button,
        a {
            position: absolute;
            left: 0;
            width: 100%;
            transform-origin: bottom;
            transition: all 400ms ease-in;
            --color-btn-clear-hover: ${({ theme }) => modifyAlpha(theme.colors.backgrounds_muted_honey, 0.7)};
            --color-btn-clear: ${({ theme }) => modifyAlpha(theme.colors.backgrounds_muted_honey, 0.7)};

            &:not(.show) {
                opacity: 0;
                pointer-events: none;
            }
            & .wrapper {
                margin: 0 auto;
            }
            &.btn-quick-view {
                top: -9rem;

                &.show {
                    transform: translateY(9rem);
                }
            }

            &.btn-add-to-cart {
                bottom: -5rem;
                &.show {
                    transform: translateY(-5rem);
                }
            }
        }

        .PopupProduct-container & {
            min-height: 30rem;
        }
    }

    .title {
    }

    .price {
        @media ${device().medium_small} {
            text-align: right;
        }
        @media ${device().medium_large} {
            text-align: left;
        }
        justify-self: end;
    }

    .tagline {
    }

    .ReviewStars-container {
        justify-self: end;
    }

    .chevron {
        width: 100%;
        height: 100%;
        justify-content: flex-start;
        --btn-padding: 0;

        &.show {
            --icon-rotate: 180deg;
        }
    }

    .review-count {
        justify-self: end;
    }

    .description {
        padding: 0;
        transition: all 200ms ease-in-out;
        z-index: 0;
        transform: scaleY(1);

        &.show {
            opacity: 1;
        }

        &.hide {
            transform: scaleY(0);
            opacity: 0;
            transition: all 200ms ease-in-out;
            pointer-events: none;
        }
    }
`;
interface ProductMetaProps {
    product: Product;
    currentVariant?: ProductVariant;
    metaData: { tagline: string; reviews: { score: number; count: number } };
    isSingle?: boolean;
    isPopup?: boolean;
    oosVariant?: boolean;
}

const ProductMeta: React.FC<ProductMetaProps> = ({
    product,
    metaData,
    currentVariant = product?.store?.variants[0],
    isSingle,
    isPopup,
    oosVariant,
}) => {
    const tooltip = 'Reviews Coming Soon!';
    const [isDescriptionVis, setDescriptionVis] = React.useState(true);
    const [isHoverControlActive, setHoverControlActive] = React.useState(false);
    const cart = useContext(CartContext);
    const { width: windowWidth } = useWindowSize();
    const router = useRouter();
    const { xxs, xs, medium_small, medium_large } = bbTheme.breakpoints;

    return (
        <>
            {tooltip && (
                <ReactTooltip
                    id={slugify(tooltip + product.store.id || '')}
                    backgroundColor={bbTheme.colors.white}
                    textColor={bbTheme.colors.black}
                    border
                    borderColor={bbTheme.colors.black}
                />
            )}
            <ProductMetaStyles
                className="ProductMeta-container no-margin"
                breakpoints={[320, medium_small, medium_large]}
                onHoverEvents={{
                    onMouseEnter: () => setHoverControlActive(true),
                    onMouseLeave: () => setHoverControlActive(false),
                    // onTouchStart: () => setHoverControlActive(!isHoverControlActive),
                    // onKeyDown: () => setHoverControlActive(!isHoverControlActive),
                }}
            >
                {isSingle ? null : (
                    <Column spacing={[0, 0]} widths={[12, 12, 12]} className="image">
                        {isPopup ? (
                            <Image
                                src={product.store.previewImageUrl}
                                width={undefined}
                                height={undefined}
                                layout={'fill'}
                                objectFit={'cover'}
                                loader={shopifyImageLoader}
                                onTouchEnd={() => setHoverControlActive(!isHoverControlActive)}
                                onKeyDown={() => setHoverControlActive(!isHoverControlActive)}
                            />
                        ) : (
                            <Image
                                src={product.store.previewImageUrl}
                                // width={SHOPIFY_BASE_IMAGE_SIZE_W}
                                // height={SHOPIFY_BASE_IMAGE_SIZE_H}
                                layout="fill"
                                objectFit="cover"
                                loader={shopifyImageLoader}
                                onTouchEnd={() => setHoverControlActive(!isHoverControlActive)}
                                onKeyDown={() => setHoverControlActive(!isHoverControlActive)}
                            />
                        )}
                        {windowWidth > 10023 ? (
                            // TODO Adapt for Dynamic Routing
                            <MyLink
                                hrefProp={`${router.asPath.replace(/\?.*/, '')}?title=${product.store.slug.current}`}
                                as={`/shop/products/${product.store.slug.current}`}
                                className={`heading-6 btn-quick-view ${isHoverControlActive ? 'show' : ''}`}
                                text="Quick View"
                                icon={{ name: 'eye' }}
                                btnStyle="clear"
                                scroll={false}
                            />
                        ) : (
                            <MyLink
                                scroll
                                hrefProp={`/shop/products/${product.store.slug.current}`}
                                as={`/shop/products/${product.store.slug.current}`}
                                className={`heading-6 btn-quick-view ${isHoverControlActive ? 'show' : ''}`}
                                text="View"
                                icon={{ name: 'eye' }}
                                btnStyle="clear"
                            />
                        )}
                        <Button
                            className={`heading-6 btn-add-to-cart ${isHoverControlActive ? 'show' : ''}`}
                            btnStyle="primary"
                            disabled={
                                oosVariant ||
                                !currentVariant.store.inventory.isAvailable ||
                                isInCart(cart.items, currentVariant?.store.gid)
                            }
                            func={() =>
                                getCustomization(product, [generateBaseCustomization(product, 1)]).quantity
                                    ? addToCart(
                                          product,
                                          getCustomization(product, [generateBaseCustomization(product, 1)]),
                                          cart.addItem,
                                      )
                                    : null
                            }
                            text={
                                !currentVariant.store.inventory.isAvailable
                                    ? 'Out of Stock'
                                    : currentVariant && isInCart(cart.items, currentVariant.store.gid || '')
                                    ? 'In cart'
                                    : 'Add to cart'
                            }
                        />
                    </Column>
                )}
                <Column spacing={[0, 0]} className="title" widths={[12, 9, 12]}>
                    <ElementLink
                        hrefProp={`/shop/products/[title]${
                            currentVariant?.store.option1
                                ? '?' + optionsToQueryString(createSelectedOptionInput(product, currentVariant))
                                : ''
                        }`}
                        as={`/shop/products/${product.store.slug.current}${
                            currentVariant?.store.option1
                                ? '?' + optionsToQueryString(createSelectedOptionInput(product, currentVariant))
                                : ''
                        }`}
                        text=""
                        passThrough={isSingle}
                        func={() => logSingleProductClick(product, undefined, product.store.slug.current, 'viewPDP')}
                    >
                        <Title as={isSingle ? 'h1' : 'h4'} className={`${isSingle ? 'heading-3' : ''} title`}>
                            {product.store.title}
                        </Title>
                    </ElementLink>
                </Column>
                <Column spacing={[0, 0]} widths={[12, 9, 12]}>
                    {metaData.tagline && <p className="tagline italics">{metaData.tagline}</p>}
                </Column>

                <Column spacing={[0, 0]} widths={[9, 3, 9]}>
                    <h5 className="price">€{currentVariant?.store.price || product.store.variants[0].store.price}</h5>
                </Column>
                {/* // TODO - In Stock Notice  */}
                {/* <strong>€{currentVariant && currentVariant.priceV2.amount}</strong>
                        {['cut-flowers', 'wedding-flowers'].indexOf(product.productType) === -1 && (
                            <span>{currentVariant.quantityAvailable} In Stock</span>
                        )} */}
                {/* <Column spacing={[0, 0]} widths={[9, 9, 9]}>
                    {metaData.tagline && <p className="tagline italics">{metaData.tagline}</p>}
                </Column> */}
                <Column spacing={[0, 0]} widths={[3, 9, 3]}>
                    <span data-tip={tooltip} data-for={slugify(tooltip + product.store.id || '')}>
                        <ReviewStars score={metaData.reviews.score} />
                    </span>
                </Column>

                {isSingle ? (
                    <Column spacing={[0, 0]} widths={[9, 9, 9]}></Column>
                ) : (
                    <Column spacing={[0, 0]} widths={[7, 7, 7]}>
                        <Button
                            className={`chevron ${isDescriptionVis ? 'show' : 'hide'}`}
                            title={isDescriptionVis ? 'Hide Description' : 'Show Description'}
                            icon={{ name: 'chevron' }}
                            btnStyle="clear"
                            func={() => setDescriptionVis(!isDescriptionVis)}
                        />
                    </Column>
                )}
                <Column spacing={[0, 0]} widths={[5, 5, 5]} className="hide-for-small review-column">
                    {/* <span className="review-count caption-text micro">({metaData.reviews.count} Reviews)</span> */}
                </Column>
                <Column spacing={[0, 0]} widths={[12, 12, 12]}>
                    <ProductDescription isSingle={isSingle} product={product} isDescriptionVis={isDescriptionVis} />
                </Column>
            </ProductMetaStyles>
        </>
    );
};

export default ProductMeta;
