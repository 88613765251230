import React, { ReactNode, useContext, useEffect, useRef } from 'react';
import { CartContext, MyAppContext } from '../../../state/context/global';
import { CartModalStyles, ModalBackDropStyles, ModalStyleProps, ModalStyles } from './ModalStyles';
import ClientOnlyPortal from '../Portal';
import usePointer from 'hooks/usePointer';
import FocusTrap from 'focus-trap-react';

interface ModalProps extends ModalStyleProps {
    children?: ReactNode;
    isOpen: boolean;
    bgColor?: string;
    coords?: { t: string; l: string; b: string; r: string };
    isSuccess?: boolean;
}

const Modal: React.FC<ModalProps> = ({ children, isOpen, bgColor, coords, isSuccess }) => {
    const divRef = useRef<HTMLDivElement | null>(null);

    const cart = useContext(CartContext);
    const app = useContext(MyAppContext);

    const mousePos = usePointer(divRef);
    if (!cart || !app) return <div />;

    function handleClickOutside(e: MouseEvent | React.MouseEvent): void {
        if (null !== divRef.current && divRef.current.contains(e.target as Node)) {
            // inside click
            return;
        }

        if (app.popupProduct) {
            app.setPopupProductDismissed();
            document.removeEventListener('mousedown', handleClickOutside, true);
        }

        // outside click
        if (cart.modalComponent) {
            cart.toggleCartModal(undefined);

            document.removeEventListener('mousedown', handleClickOutside, true);
        }
    }

    useEffect(() => {
        if (app.isModalOpen || cart.modalComponent) {
            document.addEventListener('mousedown', handleClickOutside, true);
        } else {
            document.removeEventListener('mousedown', handleClickOutside, true);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside, true);
        };
    }, [app.isModalOpen, cart.modalComponent]);

    return (
        <>
            {isOpen && (
                <ClientOnlyPortal selector="#modal">
                    <ModalBackDropStyles onClick={(e) => handleClickOutside(e)}>
                        {cart.modalComponent ? (
                            <CartModalStyles
                                aria-modal={true}
                                ref={divRef}
                                bgColor={bgColor}
                                className={`${isSuccess ? 'success' : ''}`}
                                // mousePos={{ x: mousePos.pageX || 0, y: mousePos.pageY || 0 }}
                            >
                                <FocusTrap active={isOpen}>{children}</FocusTrap>
                            </CartModalStyles>
                        ) : (
                            <ModalStyles
                                aria-modal={true}
                                ref={divRef}
                                bgColor={bgColor}
                                coords={coords}
                                className={`${isSuccess ? 'success' : ''}`}
                                // mousePos={{ x: mousePos.pageX || 0, y: mousePos.pageY || 0 }}
                            >
                                <FocusTrap active={isOpen}>{children}</FocusTrap>
                            </ModalStyles>
                        )}
                    </ModalBackDropStyles>
                </ClientOnlyPortal>
            )}
        </>
    );
};

export default Modal;
