import { genDefaultMetaTags } from 'core/metaGenerators';
import Head from 'next/head';
import React from 'react';
import { MetaTags } from '../../src/interfaces/tag';

type Props = {
    tags?: MetaTags;
    structuredData?: any;
};
const Meta: React.FC<Props> = ({ tags, structuredData }) => {
    const metaTags = tags || genDefaultMetaTags();

    return (
        <>
            <Head>
                {/* <meta name="viewport" content="width=device-width, initial-scale=1" /> */}
                <meta name="viewport" content="width=device-width, initial-scale=1.0,,user-scalable=1" />
                <meta charSet="utf-8" />
                <link rel="shortcut icon" href="/favicon.png" />
                <link rel="stylesheet" type="text/css" href="/nprogress.css" />
                <link rel="stylesheet" type="text/css" href="/reset.css" />
                <link
                    href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,500,600,700&display=swap"
                    rel="stylesheet"
                />

                <title></title>
                <title key="title">{metaTags?.title || 'Bumblebee Flower Farm'}</title>

                <meta name="description" key="description" content={metaTags.description} />

                {/* Begin OpenGraph Tag */}
                <meta property="og:type" key="og_type" content={metaTags.type} />
                {/* this meta tags helps Facebook appropriately title the page when shared within its social network */}
                <meta property="og:title" key="og_title" content={metaTags.title} />
                {/* Helps facebook describe the page */}
                <meta property="og:description" key="og_description" content={metaTags.description} />
                {/* The URL of the main page */}
                <meta property="og:url" key="og_URL" content={metaTags.og_URL ? metaTags.og_URL : metaTags.canonical} />
                {/* A url of an image for Facebook to use in a preview. */}
                <meta
                    property="og:image"
                    key="og_image"
                    content={metaTags.og_image ? metaTags.og_image : metaTags.image}
                />
                <meta
                    property="og:image:secure_url"
                    key="og_image_secure_url"
                    content={metaTags.og_image ? metaTags.og_image : metaTags.image}
                />

                <meta
                    property="og:site_name"
                    key="og_site_name"
                    content={metaTags.og_site_name ? metaTags.og_site_name : metaTags.title}
                />
                <meta property="og:locale" content="en_IE" />
                {/* End of OpenGraph Tag */}

                {/*Twitter displayed card*/}
                {/* {metaTags.twitter_site ? <meta name="twitter:card" key="twitter_card" content="summary" /> : null} */}

                {/*this will define the description of the post*/}
                {metaTags.description ? (
                    <meta name="twitter:description" key="twitter_description" content={metaTags.description} />
                ) : null}

                {/* Twitter account */}
                {metaTags.twitter_site ? (
                    <meta name="twitter:site" key="twitter_site" content={metaTags.twitter_site} />
                ) : null}
                {/* Name of Website */}
                {metaTags.twitter_domain ? (
                    <meta name="twitter:domain" key="twitter_domain" content={metaTags.twitter_domain} />
                ) : null}

                {/* Image preview of shared post */}
                {metaTags.twitter_site ? (
                    <meta name="twitter:image:src" key="twitter_img" content={metaTags.image} />
                ) : null}

                {/* End of Twitter Display card */}

                <meta name="robots" content={`${metaTags.robots}`} />

                {/* The URL of the canonical tags */}
                <link rel="canonical" key="canonical" href={metaTags.canonical} />
                <meta name="facebook-domain-verification" content="yoky0yj54h298i3k5entigw64748pw" />
                {structuredData && (
                    <script
                        type="application/ld+json"
                        dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }}
                    />
                )}
            </Head>
        </>
    );
};

export default Meta;
