import { Product } from 'components/shop/types';
// import { WithContext, Article, Product } from 'schema-dts';
import dayjs from 'dayjs';
import { Article } from 'src/types/sanity/schema';
import { Page } from 'src/types/sanity/new-schema';
import { urlFor } from 'utils/sanity/sanity';
import { GenericContentObject } from '../components/templates/ContentTemplate';
import { defaultMetaTags, siteTitle, siteUrl, socialLinks } from './constants';
import blocksToText from 'new-studio/utils/blocksToText';

export function faqSchema(contentArr: GenericContentObject[]) {
    const qs = contentArr
        .map((con) => {
            const { header, lead, content, menu } = con;
            return lead.map((el, idx) => {
                return {
                    q: el,
                    a: content[idx],
                };
            });
        })
        .reduce((acc, val) => acc.concat(val), []);
    const schema = {
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        mainEntity: qs.map((item) => ({
            '@type': 'Question',
            name: item.q.text,
            acceptedAnswer: {
                '@type': 'Answer',
                text: item.a.text,
            },
        })),
    };
    return schema;
}

export function articleSchema(article: Article) {
    // const schema: WithContext<Article> = {
    const schema = {
        '@context': 'https://schema.org',
        '@type': 'BlogPosting',
        mainEntityOfPage: {
            '@type': 'BlogPosting',
            '@id': 'https://bumblebeeflowerfarm.ie/journal/' + article.slug,
        },
        headline: article.title,
        image: urlFor(article.mainImage).url() || '',
        author: {
            '@type': 'Florist',
            name: article['author']['name'] || siteTitle,
        },
        publisher: {
            '@type': 'Florist',
            name: siteTitle,
            logo: {
                '@type': 'ImageObject',
                url: defaultMetaTags.logo,
            },
        },
        datePublished: article.publishedAt,
    };

    return schema;
}

export function productSchema(product: Product) {
    const hasSizes = product.store.options.find((el) => el.name === 'Size');
    const offerCount = hasSizes?.values.length;
    const sku = product.store.variants[0].store.sku;
    // const schema: WithContext<Product> = {
    const schema = {
        '@context': 'https://schema.org',
        '@type': 'Product',
        '@id': product.store.gid,
        name: product.store.title,
        image: product.store.previewImageUrl,
        price: product.store.priceRange.minVariantPrice,
        // @ts-ignore
        description: blocksToText(product.description),
        brand: { '@type': 'Brand', '@id': 'BBFF', name: 'Bumblebee Flower Farm' },
        sku,
        review: {
            '@type': 'Review',
            reviewRating: {
                '@type': 'Rating',
                ratingValue: '5',
                bestRating: '5',
            },
            author: { '@type': 'Person', name: 'Keja Darrion' },
        },
        aggregateRating: {
            '@type': 'AggregateRating',
            ratingValue: 4.4,
            reviewCount: 5,
        },

        offers: product.store.variants.map((v) => ({
            '@type': 'AggregateOffer',
            url: 'https://bumblebeeflowerfarm.ie/shop/products/' + product.store.slug.current,
            priceCurrency: 'EUR',
            lowPrice: product.store?.priceRange?.minVariantPrice,
            highPrice: product.store?.priceRange?.maxVariantPrice,
            price: v.store.price,
            priceValidUntil: `${dayjs().year()}-12-31`,
            itemCondition: 'NewCondition',
            availability: v.store.inventory.isAvailable ? 'InStock' : 'OutOfStock',
            eligibleRegion: 'IE',
            offerCount: offerCount || 1,
            sku,
            seller: {
                '@type': 'Organization',
                name: 'Bumblebee Flower Farm',
            },
        })),
    };

    return schema;
}

export function eventSchema(product: Product) {
    if (product.eventDetails) {
        const { startDate, endDate, address, eventStatus, eventType, performer, eventDescription, eventOrganizer } =
            product.eventDetails;
        const { address1, address2, city, county, eircode, url, locationName } = address;

        const onlineEvent = {
            '@type': 'VirtualLocation',
            url,
        };

        const offLineEvent = {
            '@type': 'Place',
            name: locationName,
            address: {
                '@type': 'PostalAddress',
                streetAddress: address1,
                addressLocality: `${address2 ? address2 + ',' : ''} ${city ? city + ',' : ''} ${
                    county ? county + ',' : ''
                }`,
                postalCode: eircode,
                addressCountry: 'IE',
            },
        };
        const schema = {
            '@context': 'https://schema.org',
            '@type': 'Event',
            name: product.store.title,
            description: eventDescription,
            image: product.store.previewImageUrl,
            startDate: startDate,
            endDate: endDate,
            eventStatus: 'https://schema.org/EventScheduled',
            eventAttendanceMode:
                eventType === 'Place'
                    ? 'https://schema.org/OfflineEventAttendanceMode'
                    : 'https://schema.org/OnlineEventAttendanceMode',
            location:
                eventType === 'VirtualLocation'
                    ? onlineEvent
                    : eventType === 'Place'
                    ? offLineEvent
                    : [onlineEvent, offLineEvent],
            offers: {
                '@type': 'Offer',
                name: 'General Admission',
                price: product?.store?.priceRange?.maxVariantPrice,
                priceCurrency: 'EUR',
                validFrom: product['_updatedAt'],
                url: 'https://bumblebeeflowerfarm.ie/events/' + product.store.slug.current,
                availability: product.store.variants.find((el) => el.store.inventory.isAvailable)
                    ? 'https://schema.org/InStock'
                    : 'https://schema.org/OutOfStock',
            },
            performer: performer
                ? {
                      '@type': 'PerformingGroup',
                      name: performer,
                  }
                : undefined,
            organizer: eventOrganizer
                ? {
                      '@type': 'Organization',
                      name: eventOrganizer.name,
                      url: eventOrganizer.url,
                  }
                : undefined,
        };

        return schema;
    }

    return {};
}

export function websiteSchema() {
    return {
        '@context': 'http://schema.org',
        '@type': 'WebSite',
        name: siteTitle,
        url: siteUrl,
        sameAs: [
            `https://facebook.com/${socialLinks.facebook}`,
            `https://instagram.com/${socialLinks.instagram}`,
            `https://twitter.com/${socialLinks.twitter}`,
        ],
        // potentialAction: {
        //     '@type': 'SearchAction',
        //     target: 'http://example.com/pages/search_results?q={search_term}',
        //     'query-input': 'required name=search_term',
        // },
    };
}
export function pageSchema(page: Page) {
    const hero = page.pageBuilder?.find((el) => el._type === 'hero');

    const description = page?.seo?.description || hero?.['tagline'] || defaultMetaTags.description;

    return {
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        name: page.title,
        description,
        publisher: {
            '@type': 'ProfilePage',
            name: siteTitle,
        },
    };
}
