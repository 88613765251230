import Title from 'components/styles/Title';
import React, { useContext, useEffect, useState } from 'react';
import { CartContext, UserContext } from '../../../state/context/global';
import { logModalView } from '../../../utils/analytics';
import Button from '../../Layout/Buttons';
import { MyLink } from '../../Layout/Link';
import Modal from '../../Layout/Modal/Modal';
import { CartItemProductNode } from '../interfaces';
import { CartProductModalStyles } from './CartProductStyles';
import SingleCartProduct from './Single';

interface ATCModalProps {
    cartProduct: CartItemProductNode | undefined;
}

export function trimString(string: string, minLength: number, maxLength: number, splitChar = '.'): string {
    if (string.length > maxLength) {
        const chunks = string.split(splitChar);
        const half = Math.floor(chunks.length / 2);
        const newStrArr =
            chunks.slice(0, half).join(splitChar).length > maxLength
                ? chunks.slice(0, half)
                : chunks.slice(0, half + 1);
        return newStrArr.join(splitChar);
    }

    return string;
}

const ATCModal: React.FC<ATCModalProps> = ({ cartProduct }) => {
    const [isSuccess, setSuccess] = useState(false);
    const [hasLogged, setHasLogged] = useState(false);
    const cart = useContext(CartContext);
    const user = useContext(UserContext);

    useEffect(() => {
        return () => {
            cart.toggleCartModal(undefined);
        };
    }, []);

    useEffect(() => {
        if (isSuccess) {
            const successTimer = setTimeout(() => {
                cart.toggleCartModal(undefined);
            }, 300);
            successTimer;
            return () => {
                clearTimeout(successTimer);
            };
        }
        return () => null;
    }, [isSuccess]);

    if (cartProduct !== undefined && cart) {
        if ((user?.cookieStatus === 'accept', !hasLogged)) {
            logModalView(
                `Added to cart: ${cartProduct.store.title} - ${cartProduct.customization.currentVariant.store.title}`,
            );
            setHasLogged(true);
        }
        return (
            <Modal isOpen={!!cart.modalComponent} isSuccess={isSuccess}>
                <CartProductModalStyles>
                    <Title as="h5" className="atc-title">
                        Added To Cart
                    </Title>
                    <SingleCartProduct isModalCart idx={0} product={cartProduct} />
                    <div className="flex-gap space-between flex-gap-wrap">
                        <Button className="btn btn-continue" text="Continue Shopping" func={() => setSuccess(true)} />{' '}
                        <MyLink
                            text="Checkout"
                            hrefProp="/cart"
                            className="btn btn-expand"
                            func={() => setSuccess(true)}
                            btnStyle="primary"
                        />
                    </div>
                </CartProductModalStyles>
            </Modal>
        );
    }
    return <span></span>;
};

export default ATCModal;
