import { CartItemProductNode } from '../interfaces';

export function isInCart(cartItems: CartItemProductNode[], currentVariant: string): boolean {
    if (cartItems) {
        if (
            cartItems.find((cartItem) => {
                const vId = cartItem.customization.currentVariant.store.gid;
                return vId === currentVariant;
            })
        ) {
            return true;
        }
    }
    return false;
}
